import '../sass/project.scss';

/* Project specific Javascript goes here. */
Array.from(document.getElementsByClassName('--jb-navbar-menu-toggle')).forEach(el => {
  el.addEventListener('click', e => {
    document.getElementById(e.currentTarget.getAttribute('data-target')).classList.toggle('active')
  })
})

Array.from(document.getElementsByClassName('mobile-aside-button')).forEach(el => {
  el.addEventListener('click', e => {
    document.documentElement.classList.toggle('aside-mobile-expanded')
  })
})
